<template>
  <div id="app">
    <NavBar />
    <main>
      <HeroSection />
      <ServicesSection />
      <FounderSection />
    </main>
    <FooterSection />
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import HeroSection from './components/HeroSection.vue';
import ServicesSection from './components/ServicesSection.vue';
import FounderSection from './components/FounderSection.vue';
import FooterSection from './components/FooterSection.vue';

export default {
  name: 'App',
  components: {
    NavBar,
    HeroSection,
    ServicesSection,
    FounderSection,
    FooterSection
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  color: #333;
  line-height: 1.6;
  height: 100%;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

h1 {
  font-size: 48px;
  margin-bottom: 20px;
  color: #0b4f9c;
  font-weight: 700;
  line-height: 1.2;
}

h2 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #0b4f9c;
  font-weight: 600;
}

p {
  font-size: 18px;
  margin-bottom: 30px;
  font-weight: 400;
  color: #555;
}

.cta-button {
  display: inline-block;
  background-color: #0b4f9c;
  color: white;
  padding: 12px 24px;
  text-decoration: none;
  font-size: 18px;
  border-radius: 5px;
  transition: all 0.3s;
  font-weight: 600;
}

.cta-button:hover {
  background-color: #083d7a;
  transform: translateY(-2px);
}

.section-title {
  text-align: center;
  color: #0b4f9c;
  font-size: 2.5rem;
  margin-bottom: 50px;
}

@media (max-width: 768px) {
  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 28px;
  }

  p {
    font-size: 16px;
  }
}

/* You can add more global styles here */
</style>