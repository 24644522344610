<template>
  <footer>
    <div class="container">
      <div class="footer-content">
        <div class="footer-logo">
          <img src="@/assets/img/tidewater_logo_full.png" alt="Tidewater Research Logo">
        </div>
        <div class="footer-links">
          <ul>
            <li><a href="#hero">Home</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#founder">Founder</a></li>
            <li><a href="#" @click.prevent="$emit('openContactModal')">Contact</a></li>
          </ul>
        </div>
        <div class="footer-social">
          <!-- <a href="#" target="_blank" rel="noopener noreferrer">
            <i class="fab fa-linkedin"></i> -->
          <!-- </a> -->
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <p>&copy; {{ currentYear }} Tidewater Research. All rights reserved.</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterSection',
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
}
</script>

<style scoped>
footer {
  /* Updated background color */
  background-color: #1C2541;
  color: white;
  padding: 40px 0 0;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.footer-logo {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
}

.footer-logo img {
  height: 90px; /* Increased from 30px to 50px */
  margin-right: 10px;
  /* Added filter to change logo color if needed */
  filter: brightness(0) invert(1);
}

.footer-links ul {
  list-style-type: none;
  padding: 0;
}

.footer-links ul li {
  margin-bottom: 10px;
}

.footer-links ul li a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links ul li a:hover {
  /* Updated hover color */
  color: #3AAFA9;
}

.footer-social a {
  color: white;
  font-size: 24px;
  margin-left: 20px;
  transition: color 0.3s;
}

.footer-social a:hover {
  /* Updated hover color */
  color: #3AAFA9;
}

.footer-bottom {
  text-align: center;
  padding: 10px 0;
  font-size: 0.9rem;
  /* Updated border color */
  border-top: 1px solid rgba(58, 175, 169, 0.2);
}

.footer-bottom p {
  margin: 0;
  /* Updated color for better readability */
  color: #a0a0a0;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-logo, .footer-links, .footer-social {
    margin-bottom: 20px;
  }

  .footer-social {
    text-align: center;
  }

  .footer-social a {
    margin: 0 10px;
  }
}
</style>