<template>
  <section id="hero">
    <canvas ref="animationCanvas" class="animation-canvas"></canvas>
    <div class="hero-content-wrapper">
      <div class="hero-content" :class="{ 'is-mobile': isMobile, 'is-flipped': isFlipped }">
        <div class="hero-face hero-front">
          <h1>Navigating the AI Revolution</h1>
          <p>
            Tidewater Research brings together elite AI strategists from <span class="emphasis">tech giants</span> and <span class="emphasis">Fortune 500</span> companies. Our 
            <span class="emphasis">battle-tested expertise</span> drives <span class="emphasis">measurable ROI</span> and 
            <span class="emphasis">sustainable competitive advantage</span> for enterprises ready to 
            lead in the AI era.
          </p>
          <a href="#" class="cta-button" @click.prevent="showContact">Contact Us Now</a>
        </div>
        <div class="hero-face hero-back" v-if="!isMobile || showContactInfo">
          <h2>Let's Connect</h2>
          <p>Ready to transform your business with AI? Reach out to start the conversation:</p>
          <div class="contact-options">
            <div class="email-container">
              <a href="mailto:kyle@tidewaterresearch.com" class="contact-link">
                <i class="fas fa-envelope"></i> kyle@tidewaterresearch.com
              </a>
              <button @click="copyEmail" class="copy-button">
                <i class="fas fa-copy"></i>
              </button>
            </div>
            <a href="https://www.linkedin.com/in/gkjohns" target="_blank" rel="noopener noreferrer" class="contact-link">
              <i class="fab fa-linkedin"></i> Connect on LinkedIn
            </a>
          </div>
          <a v-if="!isMobile" href="#" class="cta-button secondary" @click.prevent="flipCard">Back to Overview</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import * as THREE from 'three';

export default {
  name: 'HeroSection',
  data() {
    return {
      isFlipped: false,
      isMobile: false,
      showContactInfo: false,
    };
  },
  mounted() {
    this.initThreeJS();
    this.checkMobile();
    window.addEventListener('resize', this.onWindowResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
    this.stopAnimation();
  },
  methods: {
    flipCard() {
      if (!this.isMobile) {
        this.isFlipped = !this.isFlipped;
      }
    },
    showContact() {
      if (this.isMobile) {
        this.showContactInfo = true;
      } else {
        this.flipCard();
      }
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    initThreeJS() {
      this.scene = new THREE.Scene();
      this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      this.renderer = new THREE.WebGLRenderer({ canvas: this.$refs.animationCanvas, alpha: true });
      this.renderer.setSize(window.innerWidth, window.innerHeight);

      const geometry = new THREE.PlaneGeometry(20, 20, 100, 100);
      const material = new THREE.MeshBasicMaterial({
        color: 0xC0D0E0, // Updated to an even lighter version of light blue
        wireframe: true,
        transparent: true,
        opacity: 0.3
      });
      this.plane = new THREE.Mesh(geometry, material);
      this.plane.rotation.x = -Math.PI / 2;
      this.scene.add(this.plane);

      this.camera.position.z = 5;
      this.camera.position.y = 2;
      this.camera.lookAt(0, 0, 0);

      this.animate();
    },
    animate() {
      this.animationId = requestAnimationFrame(this.animate);

      const time = Date.now() * 0.001;
      const positions = this.plane.geometry.attributes.position.array;

      for (let i = 0; i < positions.length; i += 3) {
        const x = positions[i];
        const y = positions[i + 1];
        positions[i + 2] = Math.sin(x * 0.5 + time) * 0.5 + Math.sin(y * 0.5 + time) * 0.5;
      }

      this.plane.geometry.attributes.position.needsUpdate = true;

      this.renderer.render(this.scene, this.camera);
    },
    onWindowResize() {
      this.checkMobile();
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
    },
    stopAnimation() {
      if (this.animationId) {
        cancelAnimationFrame(this.animationId);
      }
    },
    copyEmail() {
      const email = 'kyle@tidewaterresearch.com';
      navigator.clipboard.writeText(email).then(() => {
        alert('Email address copied to clipboard!');
      }).catch(err => {
        console.error('Failed to copy email: ', err);
      });
    }
  }
}
</script>

<style scoped>
#hero {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: #f5f5f5;
}

.animation-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.hero-content-wrapper {
  position: absolute;
  top: 40%; /* Changed from 50% to 45% */
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 90%;
  max-width: 600px;
  perspective: 1000px;
}

.hero-content {
  width: 100%;
  position: relative;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.hero-content:not(.is-mobile) {
  min-height: 300px;
}

.hero-content:not(.is-mobile).is-flipped {
  transform: rotateY(180deg);
}

.hero-face {
  width: 100%;
  backface-visibility: hidden;
  padding: 30px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.9) 0%, rgba(245, 245, 245, 0.7) 100%);
  /* border-radius: 20px; */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.hero-content:not(.is-mobile) .hero-face {
  position: absolute;
  height: 100%;
}

.hero-content:not(.is-mobile) .hero-back {
  transform: rotateY(180deg);
}

h1, h2 {
  font-size: 2.5rem;
  margin-bottom: 15px;
  color: #1C2541;
}

h2 {
  font-size: 2rem;
}

p {
  font-size: 1rem;
  margin-bottom: 30px;
  color: #333333;
  line-height: 1.6;
}

.cta-button {
  display: inline-block;
  background-color: #3AAFA9;
  color: white;
  padding: 12px 24px;
  text-decoration: none;
  font-size: 1rem;
  /* border-radius: 5px; */
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #2a8a85;
}

.cta-button.secondary {
  background-color: #1C2541;
}

.cta-button.secondary:hover {
  background-color: #161d2f;
}

.emphasis {
  text-decoration: underline;
  text-decoration-color: #3AAFA9;
  text-decoration-thickness: 2px;
  text-underline-offset: 2px;
}

.contact-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
}

.contact-link {
  display: flex;
  align-items: center;
  color: #1C2541;
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s ease;
}

.contact-link:hover {
  color: #3AAFA9;
}

.contact-link i {
  margin-right: 10px;
  font-size: 1.3rem;
}

.email-container {
  display: flex;
  align-items: center;
}

.copy-button {
  background-color: #3AAFA9;
  color: white;
  border: none;
  /* border-radius: 5px; */
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.copy-button:hover {
  background-color: #2a8a85;
}

@media (max-width: 768px) {
  .hero-content-wrapper {
    top: 40%; /* Changed from 50% to 45% */
    width: 100%;
    max-width: none;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .hero-content.is-mobile .hero-face {
    margin-bottom: 20px;
    padding: 20px;
  }

  .hero-face {
    width: 100%;
    box-sizing: border-box;
  }

  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  p {
    font-size: 0.9rem;
    margin-bottom: 20px;
  }

  .cta-button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }

  .contact-link {
    font-size: 1rem;
  }

  .contact-link i {
    font-size: 1.2rem;
  }

  .email-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .copy-button {
    margin-left: 0;
    margin-top: 5px;
  }
}

@media (max-width: 480px) {
  .hero-content-wrapper {
    padding: 0 15px;
  }

  .hero-content.is-mobile .hero-face {
    padding: 15px;
  }

  h1 {
    font-size: 1.6rem;
  }

  h2 {
    font-size: 1.4rem;
  }

  p {
    font-size: 0.85rem;
  }
}
</style>