<template>
  <header>
    <div class="container">
      <div class="header-content">
        <div class="logo">
          <img src="@/assets/img/tidewater_logo.png" alt="Tidewater Research Logo">
          <div class="company-name">
            <span class="tidewater">Tidewater</span>
            <span class="research">Research</span>
          </div>
        </div>
        <nav>
          <ul>
            <li><a href="#hero">Home</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#founder">Founder</a></li>
            <li><a href="#" @click.prevent="$emit('openContactModal')">Contact</a></li>
          </ul>
        </nav>
        <div class="mobile-menu-icon" @click="toggleMobileMenu">
          <i class="fas fa-bars"></i>
        </div>
      </div>
    </div>
    <div class="mobile-menu" :class="{ 'is-active': isMobileMenuOpen }">
      <ul>
        <li><a href="#hero" @click="closeMobileMenu">Home</a></li>
        <li><a href="#services" @click="closeMobileMenu">Services</a></li>
        <li><a href="#founder" @click="closeMobileMenu">Founder</a></li>
        <li><a href="#" @click.prevent="openContactAndCloseMobile">Contact</a></li>
      </ul>
    </div>
  </header>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      isMobileMenuOpen: false
    }
  },
  methods: {
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    closeMobileMenu() {
      this.isMobileMenuOpen = false;
    },
    openContactAndCloseMobile() {
      this.$emit('openContactModal');
      this.closeMobileMenu();
    }
  }
}
</script>

<style scoped>
header {
  /* Updated to match hero background color */
  background-color: #f5f5f5;
  /* Changed text color to dark */
  color: #1C2541;
  padding: 8px 0;
  height: 40px;
  width: 100%;
  z-index: 1000;
  /* Removed box shadow to eliminate separation line */
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-top: 4px; /* Increased from 2px to 4px */
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 36px; /* Increased from 32px */
  margin-right: 12px; /* Increased margin for better spacing */
}

.company-name {
  display: flex;
  flex-direction: column;
  line-height: 1; /* Tighten line height for stacked text */
  align-items: center; /* Center the text horizontally */
}

.tidewater {
  font-family: 'Optima', sans-serif;
  /* font-weight: bold; */
  font-size: 18px;
  color: #1C2541;
  letter-spacing: 1px;
}

.research {
  font-family: 'Nexa', sans-serif; /* Make sure Nexa font is available */
  /* font-weight: bold; */
  font-size: 12px; /* Decreased from 18px */
  color: #1C2541;
  letter-spacing: 1px;
  margin-top: -2px; /* Adjust this value to fine-tune vertical positioning */
}

nav {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 4px; /* Increased from 2px to 4px */
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  height: 100%;
}

nav ul li {
  margin-left: 16px;
}

nav ul li a {
  color: #1C2541;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  position: relative; /* Added for positioning the pseudo-element */
  transition: none; /* Removed color transition */
}

nav ul li a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: #3AAFA9;
  transition: width 0.3s ease; /* Animation for the underline */
}

nav ul li a:hover::after {
  width: 100%; /* Full width on hover */
}

.mobile-menu-icon {
  display: none;
  font-size: 24px; /* Increased size for better visibility */
  cursor: pointer;
  color: #1C2541; /* Match the color of the nav links */
}

.mobile-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #1C2541; /* Changed to a darker color for better contrast */
  padding: 20px 0;
  z-index: 1000; /* Ensure the menu appears above other content */
}

.mobile-menu ul {
  list-style-type: none;
  padding: 0;
}

.mobile-menu ul li {
  margin-bottom: 10px;
}

.mobile-menu ul li a {
  color: #f5f5f5; /* Light color for better visibility on dark background */
  text-decoration: none;
  transition: color 0.3s;
  display: block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
}

.mobile-menu ul li a:hover {
  color: #3AAFA9;
}

.mobile-menu.is-active {
  display: block;
}

@media (max-width: 768px) {
  nav {
    display: none;
  }

  .mobile-menu-icon {
    display: block;
  }

  /* Ensure the header doesn't cover the mobile menu */
  header {
    position: relative;
  }
}
</style>