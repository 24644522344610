<template>
    <section id="founder" class="founder-section">
      <div class="container">
        <h2 class="section-title">Meet Our Founder</h2>
        <div class="founder-content">
          <div class="founder-image">
            <img src="@/assets/img/kyle_headshot.jpg" alt="Kyle Johnson">
          </div>
          <div class="founder-text">
            <div class="founder-header">
              <div>
                <h3>Kyle Johnson</h3>
                <p class="founder-title">Founder & CEO</p>
              </div>
              <div class="social-links">
                <a href="https://www.linkedin.com/in/gkjohns/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                  <i class="fab fa-linkedin"></i>
                </a>
                <a href="http://www.kylejohnson.ai" target="_blank" rel="noopener noreferrer" aria-label="Personal Website">
                  <i class="fas fa-globe"></i>
                </a>
              </div>
            </div>
            <p class="founder-bio">
              With over 6 years of experience as a Senior Data Scientist at Meta (Facebook), Kyle brings
              cutting-edge expertise in AI and machine learning to Tidewater Research. His work spans
              Reality Labs, Business Integrity, and Facebook AI Research (FAIR), giving him unique
              insights into the transformative power of AI across industries.
            </p>
            <p class="founder-bio">
              Kyle's background in Computer Science and Statistics from Columbia University, combined with
              his leadership experience as a Sergeant in the United States Marine Corps, enables him to
              bridge the gap between technical innovation and practical business solutions. At Tidewater
              Research, he's committed to helping companies navigate the complexities of AI integration
              and unlock new potentials in their analytics strategies.
            </p>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'FounderSection'
  }
  </script>
  
  <style scoped>
  .founder-section {
    /* Updated to white background */
    background-color: #ffffff;
    padding: 80px 0;
    /* Updated text color for contrast with white background */
    color: #333333;
  }
  
  .section-title {
    /* Added style for section title */
    color: #1C2541;
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 50px;
  }
  
  .founder-content {
    display: flex;
    align-items: flex-start;
    gap: 40px;
  }
  
  .founder-image {
    flex-shrink: 0;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    overflow: hidden;
    /* Updated box shadow color */
    box-shadow: 0 10px 30px rgba(28, 37, 65, 0.2);
  }
  
  .founder-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .founder-text {
    flex: 1;
  }
  
  h3 {
    /* Updated color to dark blue */
    color: #1C2541;
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .founder-title {
    font-size: 1.2rem;
    /* Updated color for better contrast on white background */
    color: #3AAFA9;
    margin-bottom: 20px;
  }
  
  .founder-bio {
    /* Added style for bio text */
    color: #333333;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .social-links {
    margin-top: 0; /* Remove top margin */
  }
  
  .social-links a {
    display: inline-block;
    margin-right: 15px;
    font-size: 24px;
    /* Updated color to dark blue */
    color: #1C2541;
    transition: color 0.3s ease;
  }
  
  .social-links a:hover {
    /* Updated hover color to accent color */
    color: #3AAFA9;
  }
  
  @media (max-width: 768px) {
    .founder-content {
      flex-direction: column;
      align-items: center;
    }
  
    .founder-image {
      width: 200px;
      height: 200px;
      margin-bottom: 30px;
    }
  
    .founder-text {
      text-align: center;
    }
  
    .founder-header {
      flex-direction: column;
      align-items: center;
    }
  
    .social-links {
      margin-top: 10px; /* Add some space between title and social links on mobile */
    }
  }
  
  .founder-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  </style>